/* Set the background image for the signup page */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

/* Style the signup card */
.signup-card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: center;
}

/* Form input and button styling */
.signup-form .form-group {
  margin-bottom: 15px;
}

.signup-form .form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signup-form .btn {
  width: 100%;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  background: linear-gradient(135deg, #d75b97 0%, #ff92d4 50%, #a54e8e);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Success message styling */
.signup-message {
  margin-top: 10px;
  font-size: 23px;
  color: #c895a2;
}
