.App {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover; /* Ensures the video covers the entire background */
  z-index: -1; /* Place the video behind the content */
}

.App-content {
  position: relative;
  z-index: 1;
  height: 100%;
}

.App-container {
  display: flex;
  flex-direction: row;
  padding: 1%;
  margin-left: 2%;
  color: #ffffff;
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1%;
  margin-left: 2%;
  color: #ffffff;
}

.App-container h1 {
  font-size: 3rem;
  margin: 0;
  font-weight: bold;
}

.App-container small {
  font-size: 1rem;
  margin-top: 10px;
  font-weight: bold;
}

.app-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  text-align: center;
  padding-top: 10%;
  color: #ffffff;
}

.app-text i {
  font-size: 80%;
  font-weight: 600;
  margin-top: 2%;
}

.logo {
  width: 80px;
  height: auto;
}

@media only screen and (max-width: 600px) {
  .App-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 1%; */
    margin-left: 1%;
    color: #ffffff;
  }

  .App-header {
    display: flex;
    padding: 1%;
    margin-left: 2%;
    color: #ffffff;
  }
}
